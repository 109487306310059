import React from "react"
import "../styles/resources.scss"

const More = props => {
  return (
    <details className='more-details'>
      <summary>{props.title}</summary>
      {props.children}
    </details>
  )
}

export default More