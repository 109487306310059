exports.components = {
  "component---src-components-page-js": () => import("./../../../src/components/page.js" /* webpackChunkName: "component---src-components-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-mdx-acerca-de-mdx": () => import("./../../../src/pages/mdx/acerca-de.mdx" /* webpackChunkName: "component---src-pages-mdx-acerca-de-mdx" */),
  "component---src-pages-mdx-introduccion-mdx": () => import("./../../../src/pages/mdx/introduccion.mdx" /* webpackChunkName: "component---src-pages-mdx-introduccion-mdx" */),
  "component---src-pages-mdx-involucrate-mdx": () => import("./../../../src/pages/mdx/involucrate.mdx" /* webpackChunkName: "component---src-pages-mdx-involucrate-mdx" */),
  "component---src-pages-mdx-organizaciones-mdx": () => import("./../../../src/pages/mdx/organizaciones.mdx" /* webpackChunkName: "component---src-pages-mdx-organizaciones-mdx" */),
  "component---src-pages-mdx-preguntas-frecuentes-mdx": () => import("./../../../src/pages/mdx/preguntas-frecuentes.mdx" /* webpackChunkName: "component---src-pages-mdx-preguntas-frecuentes-mdx" */),
  "component---src-pages-mdx-recursos-mdx": () => import("./../../../src/pages/mdx/recursos.mdx" /* webpackChunkName: "component---src-pages-mdx-recursos-mdx" */)
}

