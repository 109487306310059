import * as React from "react"
import { Link } from "gatsby"
import "../styles/decorative/link-button.scss"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

/*<div className="link-button-container">*/
const LinkButton = function ({ to, children }) {
  const isInternal = (to.charAt(0) === "/")
  if (isInternal) {
    return (
      <Link className="link-button" to={to}>
        <span>{children}</span>
        <div className="link-arrow">
          <FontAwesomeIcon className="mobile-menu-icon" icon={faArrowRight} />
        </div>
      </Link>
    )
  } else {
    return (
      <a className="link-button" href={to} target="_blank" rel="noreferrer">
        <span>{children}</span>
        <div className="link-arrow">
          <FontAwesomeIcon className="mobile-menu-icon" icon={faArrowRight} />
        </div>
      </a>
    )
  }
}
export default LinkButton
